<template>
    <v-container>
        <div>Stats </div>
    </v-container>
</template>

<script>
    export default {
        name: 'StatsRoute',
        components: {},
        data: () => {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
